export default {
  button: {
    primary: {
      base: 'text-white bg-green-500 border border-transparent',
      active: 'active:bg-green-500 hover:bg-green-600 focus:ring focus:ring-green-200',
    },
  },
  input: {
    active:
      'focus:shadow-none focus:border-green-400 border-gray-300 dark:border-gray-600 dark:focus:border-gray-600 dark:focus:ring-gray-300 dark:bg-gray-700',
    radio:
      'focus:shadow-none text-green-600 form-radio focus:border-green-400 focus:outline-none focus:ring-offset-0 dark:focus:ring-gray-300',
    checkbox:
      'text-green-600 form-checkbox focus:border-green-400 focus:outline-none focus:ring-offset-0 rounded dark:focus:ring-gray-300',
  },
  textarea: {
    active:
      'focus:shadow-none focus:border-green-400 border-gray-300 dark:border-gray-600 dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300',
  },
  select: {
    active:
      'focus:shadow-none focus:border-green-400 border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 dark:focus:border-gray-600',
  },
};
