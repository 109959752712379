import profileReducer from './profileSlice'
import tablesReducer from './tablesSlice'
import themesReducer from './themesSlice'
import questionReducer from './questionSlice'

const rootReducer = {
  profile: profileReducer.reducer,
  tables: tablesReducer.reducer,
  themes: themesReducer.reducer,
  question: questionReducer.reducer
};

export default rootReducer;