const regEx = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

const validation = {
  email: (email) => {
    if (typeof email !== "string") {
      return { isValid: false, message: "Wrong type" };
    }

    if (!regEx.email.test(email.toLocaleLowerCase())) {
      return { isValid: false, message: "This is not a valid email" };
    }

    return { isValid: true, message: null };
  },
  password: (password) => {
    if (typeof password !== "string") {
      return { isValid: false, message: "Wrong type" };
    }

    if (password.length < 6) {
      return { isValid: false, message: "Password is too short" };
    }
    return { isValid: true, message: null };
  },
};

const errorDescribe = (message) => {
  if (
    message.includes("already exist") &&
    message.includes("theme") &&
    message.includes("Error")
  ) {
    return "Такая тема уже существует";
  }

  if (
    message.includes("already exist") &&
    message.includes("question") &&
    message.includes("Error")
  ) {
    return "Такой вопрос уже существует";
  }

  return message;
};

export { validation, errorDescribe };
