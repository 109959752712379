import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendAuthData, sendAccessToken } from "../../api/profile";
import { LOADER_STATUS } from "../../utils/constants";

const initialState = {
  isAuthorized: false,
  user: null,
  status: LOADER_STATUS.idle,
  message: null,
};

export const authorizeUser = createAsyncThunk(
  "profile/authorizeUser",
  async ({ email, password }, thunkAPI) => {
    const { token, user, status } = await sendAuthData(email, password);

    if (!user) {
      thunkAPI.dispatch(setMessage("Wrong password or email"));
    }

    localStorage.setItem("accessToken", token);
    return { status, user };
  }
);

export const signInByToken = createAsyncThunk(
  "profile/signInByToken",
  async (token) => {
    const { user, status } = await sendAccessToken(token);
    return { status, user };
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setUnauthorized: (state) => {
      state.isAuthorized = false;
      state.user = null;
      state.message = null;
      state.status = LOADER_STATUS.idle;
    },
  },
  extraReducers: {
    [authorizeUser.pending]: (state) => {
      state.status = LOADER_STATUS.loading;
    },
    [authorizeUser.fulfilled]: (state, action) => {
      state.isAuthorized = action.payload.status;
      state.user = action.payload.user;
      state.status = LOADER_STATUS.idle;
    },

    [signInByToken.fulfilled]: (state, action) => {
      state.isAuthorized = action.payload.status;
      state.user = action.payload.user;
      state.status = LOADER_STATUS.idle;
    },
    [signInByToken.pending]: (state) => {
      state.status = LOADER_STATUS.global;
    },
  },
});

export const { setAuthorized, setMessage, setUnauthorized } =
  profileSlice.actions;

export default profileSlice;
