import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorDescribe } from "../../utils/helpers";

import { getTableData } from "./tablesSlice";
import {
  LOADER_STATUS,
  TABLES,
  PAGINATION_SORT,
  DEFAULT_ORDER_QUESTION,
  ACTIONS_MODE,
  MINIMAL_DATE,
} from "../../utils/constants";
import {
  sendNewQuestionItem,
  deleteQuestion,
  createNewQuestionItem,
} from "../../api/question";

const initialState = {
  sortBy: PAGINATION_SORT.fromSmallest,
  orderBy: DEFAULT_ORDER_QUESTION,
  status: LOADER_STATUS.idle,
  selectedElements: [],
  questionList: [],
  message: null,
  fullLength: 0,
  filter: [],
  from: MINIMAL_DATE,
  to: Date.now(),
  page: 1,
};

const questionRequests = {
  [ACTIONS_MODE.edit]: sendNewQuestionItem,
  [ACTIONS_MODE.create]: createNewQuestionItem,
  [ACTIONS_MODE.delete]: deleteQuestion,
};

export const sendQuestionItem = createAsyncThunk(
  "question/sendQuestionItem",
  async ({ item, mode, page, sortBy, orderBy, filter = "" }, thunkAPI) => {
    const req = await questionRequests[mode](item);

    if (req.status) {
      thunkAPI.dispatch(setSuccess());
    } else {
      const currentMessage = errorDescribe(req.message);
      thunkAPI.dispatch(setError(currentMessage));
    }

    thunkAPI.dispatch(
      getTableData[TABLES.question]({
        page,
        sortBy,
        orderBy,
        filter,
        from: MINIMAL_DATE,
        to: Date.now() + 10000,
      })
    );
  }
);

const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setList: (state, action) => {
      state.questionList = action.payload;
    },
    setFullLength: (state, action) => {
      state.fullLength = action.payload;
    },
    setOrder: (state, action) => {
      state.orderBy = action.payload;
    },
    setSort: (state, action) => {
      state.sortBy = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setError: (state, action) => {
      state.message = action.payload;
      state.status = LOADER_STATUS.error;
    },
    setDefault: (state) => {
      state.filter = [];
      state.message = null;
      state.status = LOADER_STATUS.idle;
    },
    setSuccess: (state) => {
      state.status = LOADER_STATUS.success;
    },
    setInitialPagination: (state) => {
      state.page = 1;
      state.orderBy = DEFAULT_ORDER_QUESTION;
      state.sortBy = PAGINATION_SORT.fromSmallest;
      state.filter = [];
      state.from = MINIMAL_DATE;
      state.to = Date.now();
    },
    addSelectedElement: (state, action) => {
      state.selectedElements = [...state.selectedElements, action.payload];
    },
    setSelectedElement: (state, action) => {
      state.selectedElements = action.payload;
    },
    setFromDate: (state, action) => {
      state.from = action.payload;
    },
    setToDate: (state, action) => {
      state.to = action.payload;
    },
  },
  extraReducers: {
    [sendQuestionItem.pending]: (state) => {
      state.status = LOADER_STATUS.loading;
    },
  },
});

export const {
  setPage,
  setList,
  setSort,
  setOrder,
  setError,
  setFilter,
  setToDate,
  setSuccess,
  setDefault,
  setFromDate,
  setFullLength,
  addSelectedElement,
  setSelectedElement,
  setInitialPagination,
} = questionSlice.actions;

export default questionSlice;
