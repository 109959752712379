import Http from "./http";

export const sendAuthData = async (email, password) => {
  try {
    const response = await Http.Public().post("/auth/login", {
      email: email,
      password: password,
    });
    if (response.status === 200) {
      return {
        token: response.data.response.auth_token,
        user: { email: email },
        status: true,
      };
    }
    throw new Error("403");
  } catch (error) {
    return {
      token: null,
      user: null,
      status: false,
    };
  }
};

export const sendAccessToken = async (token) => {
  try {
    const response = await Http.Private().get("/api/profile");
    if (response.status === 200) {
      return {
        token: token,
        user: response.data.response,
        status: true,
      };
    }
    throw new Error("403");
  } catch (error) {
    return {
      token: null,
      user: null,
      status: false,
    };
  }
};
