import React, { lazy, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import GlobalLoader from "./components/GlobalLoader";

const Layout = lazy(() => import("./containers/Layout"));
const Authorization = lazy(() => import("./pages/Authorization"));

function App() {
  const location = useLocation();
  const { isAuthorized } = useSelector((state) => state.profile);
  const [prevLocation, setPrevLocation] = useState("/app/theme");

  useEffect(() => {
    if (
      location.pathname !== prevLocation &&
      location.pathname.includes("app")
    ) {
      setPrevLocation(location.pathname);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <AccessibleNavigationAnnouncer />
      <GlobalLoader />
      <Switch>
        {isAuthorized ? (
          <>
            <Route path="/app" component={Layout} />
            <Redirect to={prevLocation} />
          </>
        ) : (
          <>
            <Route path="/login" component={Authorization} />
            <Redirect to="/login" />
          </>
        )}
      </Switch>
    </>
  );
}

export default App;
