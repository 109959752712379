import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { LOADER_STATUS, TABLES, RESULTS_PER_PAGE } from "../../utils/constants";
import {
  setInitialPagination as setInitialThemes,
  setFullLength as setThemesFullLength,
  setDefault as setThemeDefault,
  setOrder as setThemeOrder,
  setPage as setThemesPage,
  setList as setThemesList,
  setSort as setThemeSort,
} from "./themesSlice";
import {
  setInitialPagination as setInitialQuestions,
  setFullLength as setQuestionFullLength,
  setDefault as setQuestionDefault,
  setFilter as setQuestionFilter,
  setOrder as setQuestionOrder,
  setList as setQuestionList,
  setPage as setQuestionPage,
  setSort as setQuestionSort,
  setFromDate,
  setToDate
} from "./questionSlice";
import { getThemesItems } from "../../api/themes";
import { getQuestionItems } from "../../api/question";

const initialState = {
  status: LOADER_STATUS.idle,
};

export const setDefaultLoader = {
  [TABLES.theme]: setThemeDefault,
  [TABLES.question]: setQuestionDefault,
};

export const setInitialPagination = {
  [TABLES.theme]: setInitialThemes,
  [TABLES.question]: setInitialQuestions,
};

export const getTableData = {
  [TABLES.theme]: createAsyncThunk(
    "tables/getThemeData",
    async ({ page, sortBy, orderBy, filter = "" }, thunkAPI) => {
      const { themes, totalItems } = await getThemesItems(
        page,
        RESULTS_PER_PAGE,
        sortBy,
        orderBy,
        filter
      );
      thunkAPI.dispatch(setThemesList(themes));
      thunkAPI.dispatch(setThemesPage(page));
      thunkAPI.dispatch(setThemeOrder(orderBy));
      thunkAPI.dispatch(setThemeSort(sortBy));
      thunkAPI.dispatch(setThemesFullLength(totalItems));
    }
  ),
  [TABLES.question]: createAsyncThunk(
    "tables/getQuestionData",
    async ({ page, sortBy, orderBy, filter = [], from, to }, thunkAPI) => {
      const { questions, totalItems } = await getQuestionItems(
        page,
        RESULTS_PER_PAGE,
        sortBy,
        orderBy,
        filter,
        from,
        to
      );
      thunkAPI.dispatch(setQuestionList(questions));
      thunkAPI.dispatch(setQuestionPage(page));
      thunkAPI.dispatch(setQuestionOrder(orderBy));
      thunkAPI.dispatch(setQuestionSort(sortBy));
      thunkAPI.dispatch(setQuestionFilter(filter));
      thunkAPI.dispatch(setQuestionFullLength(totalItems));
      thunkAPI.dispatch(setFromDate(from));
      thunkAPI.dispatch(setToDate(to));
    }
  ),
};

const tablesSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {},
  extraReducers: {
    [getTableData[TABLES.theme].pending]: (state) => {
      state.status = LOADER_STATUS.loading;
    },
    [getTableData[TABLES.theme].fulfilled]: (state, action) => {
      state.status = LOADER_STATUS.idle;
    },

    [getTableData[TABLES.question].pending]: (state) => {
      state.status = LOADER_STATUS.loading;
    },
    [getTableData[TABLES.question].fulfilled]: (state, action) => {
      state.status = LOADER_STATUS.idle;
    },
  },
});

export const tablesSelector = {
  [TABLES.theme]: (state) => ({
    data: state.themes.themesList,
    length: state.themes.fullLength,
    page: state.themes.page,
    sortBy: state.themes.sortBy,
    orderBy: state.themes.orderBy,
    status: state.themes.status,
    message: state.themes.message,
    selectedElements: state.themes.selectedElements,
  }),
  [TABLES.question]: (state) => ({
    data: state.question.questionList,
    length: state.question.fullLength,
    page: state.question.page,
    sortBy: state.question.sortBy,
    orderBy: state.question.orderBy,
    filter: state.question.filter,
    status: state.question.status,
    message: state.question.message,
    selectedElements: state.question.selectedElements,
    from: state.question.from,
    to: state.question.to,
  }),
};

export const { setAuthorized, setMessage, setUnauthorized } =
  tablesSlice.actions;

export default tablesSlice;
