import React from 'react';
import { useSelector } from 'react-redux'

import { LOADER_STATUS } from '../../utils/constants'

const GlobalLoader = ({ vision }) => {
  const profileLoader = useSelector(state => state.profile.status);

  if (profileLoader === LOADER_STATUS.global || vision) {
    return (
      <div className="fixed left-0 right-0 top-0 bottom-0 flex items-center justify-center bg-white z-50">
        <span className="block font-semibold text-gray-500 text-3xl">
          Загрузка...
        </span>
      </div>
    )
  }

  return null
}

export default GlobalLoader;