import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getTableData } from "./tablesSlice";
import {
  LOADER_STATUS,
  TABLES,
  PAGINATION_SORT,
  DEFAULT_ORDER_THEME,
  ACTIONS_MODE,
} from "../../utils/constants";
import {
  sendNewThemeItem,
  deleteTheme,
  createNewThemeItem,
} from "../../api/themes";
import { errorDescribe } from "../../utils/helpers";

const initialState = {
  sortBy: PAGINATION_SORT.fromSmallest,
  orderBy: DEFAULT_ORDER_THEME,
  status: LOADER_STATUS.idle,
  themesList: [],
  fullLength: 0,
  message: null,
  page: 1,
};

const themesRequests = {
  [ACTIONS_MODE.edit]: sendNewThemeItem,
  [ACTIONS_MODE.create]: createNewThemeItem,
  [ACTIONS_MODE.delete]: deleteTheme,
};

export const sendThemeItem = createAsyncThunk(
  "themes/sendThemeItem",
  async ({ item, mode, page, sortBy, orderBy }, thunkAPI) => {
    const req = await themesRequests[mode](item);

    if (req.status) {
      thunkAPI.dispatch(setSuccess());
    } else {
      const currentMessage = errorDescribe(req.message);
      thunkAPI.dispatch(setError(currentMessage));
    }

    thunkAPI.dispatch(getTableData[TABLES.theme]({ page, sortBy, orderBy }));
  }
);

const themesSlice = createSlice({
  name: "themes",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setList: (state, action) => {
      state.themesList = action.payload;
    },
    setFullLength: (state, action) => {
      state.fullLength = action.payload;
    },
    setOrder: (state, action) => {
      state.orderBy = action.payload;
    },
    setSort: (state, action) => {
      state.sortBy = action.payload;
    },
    setError: (state, action) => {
      state.message = action.payload;
      state.status = LOADER_STATUS.error;
    },
    setDefault: (state) => {
      state.message = null;
      state.status = LOADER_STATUS.idle;
    },
    setSuccess: (state) => {
      state.status = LOADER_STATUS.success;
    },
    setInitialPagination: (state) => {
      state.page = 1;
      state.orderBy = DEFAULT_ORDER_THEME;
      state.sortBy = PAGINATION_SORT.fromSmallest;
    },
  },
  extraReducers: {
    [sendThemeItem.pending]: (state) => {
      state.status = LOADER_STATUS.loading;
    },
  },
});

export const {
  setPage,
  setList,
  setFullLength,
  setOrder,
  setSort,
  setError,
  setSuccess,
  setDefault,
  setInitialPagination,
} = themesSlice.actions;

export default themesSlice;
